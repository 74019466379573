<template>
	<div>
	 	      <div class="container boxtintuc" v-if="my_top_news==null">
             <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <div class="row">
                  <div class="col-sm-12 col-xs-12 col-ms-12">
                    <h2 class="titleh2n">
                      <Skeleton></Skeleton>
                      <a href="" :title="$t('news.see')">
                        {{$t('news.see')}}
                      </a>
                    </h2>

                  </div>
                </div>
             </SkeletonTheme>
          </div>



          <div class=" boxtintuc" :class="{ container: Header!=3 }" v-else >
              <div class="row" v-if="Header==1">
                <div class="col-sm-12 col-xs-12 col-ms-12">
                  <h2 class="titleh2n">
                    {{$t('news.new')}}
                    <a :href="'/'+$i18n.locale+'/thong-tin-moi'" :title="$t('news.new')">
                      {{$t('news.see')}}
                    </a>
                  </h2>

                </div>
              </div>
          </div>


          <div class="container" v-if="my_top_news==null">
            <div class="row justify-content-center">
              <div class="col-md-12 col-lg-12 col-xl-12">
                <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <div class="row">
                  <div class="col-md-5 col-lg-5 col-xl-5">
                    <div class="news-wrapper">
                      <a href="#" title="">
                          <Skeleton></Skeleton>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7 col-lg-7 col-xl-7 p-left">
                    <div class="news-description">
                      <h3>
                        <a href="#" title="">
                            <Skeleton></Skeleton>
                        </a>
                      </h3>
                      <h4 class="the-title">
                        <a href="#" title="">
                            <Skeleton></Skeleton>
                        </a>
                      </h4>
                      <div class="post-link text-left">
                        <span class="entry-date mrr-20">
                          <i class="fa fa-calendar mrr-10 text-primary-color"></i>
                            <Skeleton width="10"></Skeleton>
                        </span>
                        <span class="entry-date">
                          <i class="fa fa-eye mrr-10 text-primary-color"></i>
                            <Skeleton width="10"></Skeleton>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                </SkeletonTheme>
              </div>
              <div class="col-md-12 col-lg-12 col-xl-12 other_news">
              	<SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <ul>
                  <li v-for="(v,k) in 3" :key="k">
                    <a href="#" :title="v">
                      <h3>
                        <i class="fas fa-caret-right"></i>
                       	 
			                <Skeleton></Skeleton>
			              
                        <span class="text-primary-color">(     <Skeleton></Skeleton> )</span>
                      </h3>
                    </a>
                  </li>
                  
                </ul>
                </SkeletonTheme>
              </div>
            </div>
          </div>

          <div class="container" v-else >
            <div class="row justify-content-center">
              <div class="col-md-12 col-lg-12 col-xl-12" v-if="Header==1 || Header==3">
                <div class="row"  v-for="(news,key) in my_top_news" :key="key" >
                  <div class="col-md-5 col-lg-5 col-xl-5" v-if="key==0">
                    <div class="news-wrapper">
                      <a  :href="$router.resolve({name: 'news.detail',params:{locale:$i18n.locale,slug:news.slug}}).href"  :title="news.name">
                        <img :alt="news.name" class="lazy img-full" :src="myurl+''+news.picture">
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7 col-lg-7 col-xl-7 p-left" v-if="key==0">
                    <div class="news-description">
                      <h3>
                        <a  :href="$router.resolve({name: 'news.detail',params:{locale:$i18n.locale,slug:news.slug}}).href"  :title="news.name">
                           {{news.name}}
                        </a>
                      </h3>
                      <h4 class="the-title">
                        <a  :href="$router.resolve({name: 'news.detail',params:{locale:$i18n.locale,slug:news.slug}}).href"  :title="news.name">
                          {{news.description}}
                        </a>
                      </h4>
                      <div class="post-link text-left">
                        <span class="entry-date mrr-20">
                          <i class="fa fa-calendar mrr-10 text-primary-color"></i>
                          {{ news.datecreate | viewDate(news.createdTime)}}

                        </span>
                        <span class="entry-date">
                          <i class="fa fa-eye mrr-10 text-primary-color"></i>
                          {{news.viewer}} {{$t('news.view')}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-xl-12 other_news">
                <ul>
                  <li v-for="(news,key) in my_top_news" :key="key">

                    <a v-if="key>0" :href="$router.resolve({name: 'news.detail',params:{locale:$i18n.locale,slug:news.slug}}).href" 
                          :title="news.name" >
                      <h3>
                        <i class="fas fa-caret-right"></i>
                        {{news.name}}
                        <span class="text-primary-color">(   {{ news.datecreate | viewDate(news.createdTime)}})</span>
                      </h3>
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
	</div>
</template>


<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

  import axios from 'axios';


export default {
  
 
  data() {
    return {
     
      myurl:window.my_url,
      
   

      my_top_news:null


    };
  },

  components: {
    Skeleton,
    SkeletonTheme
    
    
  },
  props:["Header"],
  created(){
         axios.get(window.my_api+"api/news/get-top-news",{
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
          .then( (res)=>{
            
            if(res.status==200){
                this.my_top_news=res.data.data;
                
            }
             

          });
          
  },
  filters: {
    viewDate: function(string,createdtime) {
        if(string==null || string==undefined){
          return createdtime.substring(0,10);
        
        }          
        var today = new Date(string);

        var date = today.getDate()+"/"+(today.getMonth()+1)+"/"+today.getFullYear();

        return date;

    }
  }

}
</script>